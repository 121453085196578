<template>
  <div class="wrap-datetime-select">
    <div v-if="title" class="title">{{ title }}</div>
    <section>
      <date-picker
        ref="datePicker"
        v-model="timeRange"
        prefix-class="xmx"
        type="datetime"
        :range="range"
        :key="range ? 'range' : 'date'"
        :lang="lang"
        :disabled="disabled"
        :placeholder="$t(placeholder)"
        :show-time-panel="showTimeRangePanel"
        :disabled-date="disabledDate"
        :shortcuts="shortcutList"
        class="datetime-select"
        :class="{ hide: !range, disalbed: disabled }"
        :format="format"
        :clearable="range"
        @open="onOpen"
        @close="onClose"
        @pick="onPick"
      >
        <template v-if="!range" #input="picker">
          <div v-if="interval" class="interval" @click="showDatePicker">{{ intervalDateRange }}</div>
          <div v-else class="overlay" @click="showDatePicker">
            <img src="@/assets/icons/clock-solid.svg" alt="">
            <span>{{ dateTimeStr(picker) }}</span>
          </div>
        </template>
        <i v-if="!range" slot="icon-calendar"></i>
        <i v-if="!range" slot="icon-clean"></i>

        <template v-slot:footer>
          <div class="dt-footer">
            <!-- <span v-if="timeRange[0]">{{ timeRange[0] ? timeRange[0].toLocaleString() : '' }} ~ {{ timeRange[1] ? timeRange[1].toLocaleString() : '' }}</span> -->
            <div v-if="!dateOnly" class="btn set" @click="toggleTimeRangePanel">{{ showTimeRangePanel ? $t('search_select_date') : $t('search_select_time') }}</div>
            <!-- <div class="btn cancel" @click="hangleDatePanel">{{ $t('close') }}</div> -->
            <button v-if="!range" type="button" class="xmx-btn xmx-datepicker-btn-confirm" @click.stop="onConfirm">{{ $t('ok') }}</button>
          </div>
        </template>
      </date-picker>
    </section>
  </div>

</template>

<script>
import DatePicker from 'vue2-datepicker'
// import 'vue2-datepicker/locale/ja'
// import 'vue2-datepicker/locale/zh-tw'
// import 'vue2-datepicker/locale/en'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { getLang } from '@/i18n/i18n'

export default {
    name: 'DateRangeSelect',
    components: { DatePicker },
    props: {
      value: {
        default: null
      },
      range: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: ''
      },
      maxRangeDays: {
        type: Number,
        default: 180 // 最大可選擇區間天數
      },
      enabledDateStart: {
        default: null
      },
      enabledDateEnd: {
        default: null
      },
      interval: {
        type: Number, 
        // 搭配range=false使用，若interval = 30(分鐘)，
        // 若選擇時間為 2021-08-16 12:00，結束時間會自動設定為 2021-08-16 12:30
        // 則顯示為 2021-08-16 12:00 ~ 2021-08-16 12:30
      },
      shortcuts: {
        type: Array,
      },
      format: {
        type: String,
        default: "YYYY-MM-DD HH:mm"
      },
      placeholder: {
        type: String,
        default: 'search_time_placehold'
      },
      dateOnly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        nPick: 0,
        enabledStart: null,
        enabledEnd: null,
        showDatePanel: false,
        showTimeRangePanel: false,
        timeList: {start: '00:00', step:'00:30' , end: '23:30', format: 'HH:mm' },
        openedTime: null
      };
    },
  computed: {
    ...mapState(['userFlatObj']),
    ...mapGetters(['timezone']),
    lang() {
      const navLang = this.userFlatObj.language
        ? this.userFlatObj.language
        : navigator.language
      const currLang = getLang(navLang)

      let langCfg = require('vue2-datepicker/locale/en')
      if (currLang === 'zh') {
        langCfg = require('vue2-datepicker/locale/zh-tw')
      } else if (currLang === 'ja') {
        langCfg = require('vue2-datepicker/locale/ja')
      }

      // console.log(`[lang] currLang:`, currLang)
      const { formatLocale } = langCfg
      // formatLocale.firstDayOfWeek = 1

      return { formatLocale }
    },
    defaultShortcuts() {
      // NOTE: 搬到 computed 是為了要讓翻譯字串, 會自動更新
      return [
        {
          // 今天 00:00 ~ 23:59
          text: this.$t('today'),
          onClick: () => {
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate())
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        },
        {
          // 昨天 00:00 ~ 昨天 23:59
          text: this.$t('yesterday'),
          onClick: () => {
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        },
        {
          // 7天前 00:00 ~ 今天 23:59
          text: this.$t('last_week'),
          onClick: () => {
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        },
        {
          // 30天前 00:00 ~ 今天 23:59
          text: this.$t('last_month'),
          onClick: () => {
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 29)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        },
        {
          // 過去半年 00:00 ~ 今天 23:59
          text: this.$t('half_year_ago'),
          onClick: () => {
            const date = new Date()
            const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 179)
            const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59)
            return [startOfDay, endOfDay]
          }
        }
      ]
    },
      timeRange: {
        get() {
          let val
          if (this.range) {
            val = this.value.map(item => {
              return item ? new Date(item) : null
            })
          } else {
            val = this.value ? new Date(this.value) : null
          }
          return val
        },
        set(times) {
          if (this.range) {
            if (times.length === 2 && !times[0] && !times[1]) times = []
          } 
          this.$emit('input', times)
        }
      },
      intervalDateRange() {
        if (this.range || !this.interval || !this.value) return ''
        const dt = moment(this.value).tz(this.timezone)
        // this.value add 30 minutes
        const dt2 = moment(this.value).tz(this.timezone).add(this.interval, 'minutes')
        return `${dt.format('YYYY-MM-DD HH:mm')} ~ ${dt2.format('YYYY-MM-DD HH:mm')}`
      },
      shortcutList() {
        if (!this.range) return []
        if (this.shortcuts) return this.shortcuts
        return this.defaultShortcuts
      },
  },
    methods: {
      dateTimeStr(picker) {
        let dateTime = picker.props.value

        if (!this.range && this.openedTime) {
          dateTime = this.openedTime
        }
        if (this.dateOnly) {
          dateTime = moment(dateTime).format('YYYY-MM-DD')
        }
        return dateTime
      },
      disabledDate(date) {
        if (this.range) {
          if (!this.enabledStart || !this.enabledEnd) return false
          const dt = moment(date).tz(this.timezone)
          return dt.isBefore(this.enabledStart) || dt.isAfter(this.enabledEnd)
        } else {
          return new Date(date).getTime() > Date.now()
        }
      },
      toggleTimeRangePanel() {
        this.showTimeRangePanel = !this.showTimeRangePanel;
      },
      hangleDatePanel() {
        this.showTimeRangePanel = false
        this.showDatePanel = !this.showDatePanel
      },
      showDatePicker() {
        this.$refs.datePicker.focus()
      },
      onOpen() {
        this.nPick = 0
        this.enabledStart = this.enabledDateStart
        this.enabledEnd = this.enabledDateEnd

        if (!this.range) {
          // 1. 暫存 開啟日曆當下時間
          this.openedTime = moment(this.value).format(this.format)
          // console.log(`[DP.onClose] openedTime:`, this.openedTime)
          this.$emit('showDatePanel', true)
        }
      },
      onClose() {
        this.showDatePanel = false
        this.showTimeRangePanel = false

        // console.log(`[DP.onClose]`)
        if (!this.range) {
          if (this.openedTime) {
            // 2. 寫回 開啟日曆時間
            this.timeRange = new Date(this.openedTime)
            // console.log(`[DP.onClose] timeRange:`, this.timeRange)
            this.openedTime = null
          }
          this.$emit('showDatePanel', false)
        }
      },
      onPick(date) {
        if (!this.range) return

        this.nPick++
        if (this.nPick % 2 === 0) {
          // 設定完區間後，預設區間改為props進來的enabledDateStart, enabledDateEnd
          this.enabledStart = this.enabledDateStart
          this.enabledEnd = this.enabledDateEnd
        } else {
          // 選定開始時間後，預設區間改為開始時間前後maxRangeDays天
          this.enabledStart = moment(date).add(-this.maxRangeDays, 'days').toDate()
          this.enabledEnd = moment(date).add(this.maxRangeDays, 'days').toDate()
        }
      },
      onConfirm(/*evt*/) {
        // confirm 完, 會執行 close
        // 3. 寫入 日曆選擇時間
        // console.log(`[DP.onConfirm] value:`, this.value)
        this.$emit('confirm', this.value)
        this.openedTime = null
        this.$refs.datePicker.closePopup()
      },
  },
  // watch: {
  //   openedTime(nVal) {
  //     console.log(`[DP.W.openedTime] nVal:`, nVal)
  //     console.log(`[DP.W.openedTime] value:`, this.value)
  //     console.log(`[DP.W.openedTime] timeRange:`, this.timeRange)
  //   },
  // },
}
</script>

<style lang="scss" scoped>
.title {
  @include font_style(px2rem(14), px2rem(21));
  margin-bottom: 4px;
}

section {
  position: relative;
}

.interval {
  position: relative;
  height: px2rem(36);
  border-radius: 8px;
  padding-left: 10px;
  background-color: #FFFFFF;
  @include font_style(px2rem(16), px2rem(36), #0E1924);
}
.overlay {
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-left: 0.5rem;
  padding: 0 px2rem(12) 0;
  color: #0E1924;
  border-radius: 6px;
  font-size: px2rem(20);
  font-weight: 400;
  padding-left: 12px;
  background-color: $color_6E7D93;

  img {
    width: px2rem(16);
    height: px2rem(16);
  }
}

.datetime-select {
  width: 100%;
  letter-spacing: 0px;
  color: #FFFFFF;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    cursor: default;
  }
}

::v-deep .mx-datepicker.mx-datepicker-range {
  width: 100%;
  position: relative;
}

.dt-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 12px;
  padding: 6px 10px;
  .btn {
    padding: 6px 24px;
    font-size: px2rem(18);
    cursor: pointer;
    &.set {
      font-size: px2rem(16);
      color: #E6E6E6;
      border: 1px solid #E6E6E6;
      border-radius: 8px;

      &:hover {
        background-color: $color_FFF_10;
      }
    }
    &.cancel {
      color: #FFFFFF;
      background: #4A5C78;
      border-radius: 8px;
      border: 1px solid #4A5C78;

      &:hover {
        background-color: #6E7D93;
      }
    }
  }
}

</style>